<script>
import ezfPageHeader from '@/pages/layout/header.vue';
import ezfPageFooter from '@/pages/layout/footer.vue';
import { goToSsoPage } from '@/services/apiUser';

export default {
  name: 'ForbiddenPage',
  components: {
    ezfPageHeader,
    ezfPageFooter,
  },
  computed: {
    isAuthorized() {
      return this.$can('view', 'Funnels');
    },
  },
  methods: {
    login() {
      const { protocol, host } = window.location;
      goToSsoPage(`${protocol}//${host}`);
    },
  },
};
</script>

<template>
  <ez-layout name="funnels">
    <ezf-page-header slot="header" v-if="isAuthorized" />
    <ez-info-box full-height slot="content">
      <div slot="image" class="error-image">
        <img src="@dmant/ez-lib/src/ability/assets/403.jpg" alt="Error 403">
      </div>
      <template slot="title">
        {{ $t("403.title") }}
      </template>
      <template slot="description">
        {{ $t("403.description") }}
      </template>
      <template slot="button">
        <ez-button
          v-if="isAuthorized"
          size="large"
          type="primary"
          @click.prevent="$router.push({ path: '/' })"
        >
          {{ $t("404.backButton") }}
        </ez-button>
        <ez-button v-else size="large" type="primary" @click.prevent="login">
          {{ $t("404.loginButton") }}
        </ez-button>
      </template>
    </ez-info-box>
    <ezf-page-footer slot="footer" />
  </ez-layout>
</template>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.error-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 0;
}
.error-image {
  width: 100%;
  height: 100%;
  max-width: 375px;
  max-height: 221px;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>

<style>
body {
  background-color: #fff;
}
</style>
